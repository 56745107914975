import { FaHome, FaProjectDiagram } from 'react-icons/fa';
import { NETWORKS } from './networks';

//set the target network
export const targetNetwork = NETWORKS.matic;

export const constants = {
	logo: `Atlas Corp DAO`,
	projectName: `Atlas Corp DAO`,
	supportedChainIds: [1, 137],
	socials: {
		atlasDiscord: '84GEXy3UxM',
		discord: '84GEXy3UxM',
		twitter: 'AtlasCorp_dcl',
	},
	cookiePrefix: 'atlas',
	navMenu: [
		{
			path: '/',
			text: 'Home',
			icon: FaHome,
			include: ['sidebar', 'navbar'],
		},
		{
			path: '/projects',
			text: 'Projects',
			icon: FaProjectDiagram,
			include: ['sidebar', 'navbar'],
		},
	],
};
