import React from 'react';
import { useEffect, useState, createContext, useMemo } from 'react';
import { BigNumber, ethers, providers, Contract } from 'ethers';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import {
	NoEthereumProviderError,
	UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from '@web3-react/frame-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
// import { NetworkConnector } from '@web3-react/network-connector';
import { NetworkConnector } from '../components/NetworkConnector'; //haxed
import { toast } from 'react-toastify';
import {
	storageContractInformation,
	tokenContractInformation,
	msgsSplitterContractInformation,
	sharkzTokenContractInformation,
	dbitzTokenContractInformation,
	sharkzSplitterContractInformation,
	dbitzSplitterContractInformation,
	holySplitterContractInformation,
	holyTokenContractInformation,
	realfaceSplitterContractInformation,
	realfaceTokenContractInformation,	
	cupidsSplitterContractInformation,
	cupidsTokenContractInformation,	
	dcaveBulovaSplitterContractInformation,
	dcaveBulovaTokenContractInformation,			
	constants,
	targetNetwork,
	NETWORK,
} from '../constants';
import { log } from '..';
import { serializeError, getMessageFromCode } from 'eth-rpc-errors';
import { OpenSeaPort, Network } from 'opensea-js';

//matic dedicated node
export const maticNetwork = new NetworkConnector({
	urls: { [targetNetwork.chainId]: targetNetwork.rpcUrl },
});

//mainnet dedicated node
export const mainnetNetwork = new NetworkConnector({
	urls: { [1]: NETWORK(1)!.rpcUrl },
});

//metamask
export const injected = new InjectedConnector({
	supportedChainIds: constants.supportedChainIds,
});

export const useEagerConnect = (): boolean => {
	const { activate, active } = useWeb3React('metamask');
	const [tried, setTried] = useState(false);
	useEffect(() => {
		injected.isAuthorized().then((isAuthorized: boolean) => {
			if (isAuthorized) {
				activate(injected, undefined, true).catch(() => {
					setTried(true);
				});
			} else {
				setTried(true);
			}
		});
		// eslint-disable-next-line
	}, []); // intentionally only running on mount (make sure it's only mounted once :))

	// if the connection worked, wait until we get confirmation of that to flip the flag
	useEffect(() => {
		if (!tried && active) {
			setTried(true);
		}
	}, [tried, active]);

	return tried;
};

export function useInactiveListener(suppress = false): void {
	const { active, error, activate } = useWeb3React('metamask');

	useEffect(() => {
		const { ethereum } = window as any;
		if (ethereum && ethereum.on && !active && !error && !suppress) {
			const handleConnect = () => {
				log("Handling 'connect' event");
				activate(injected);
			};
			const handleChainChanged = (chainId: string | number) => {
				log("Handling 'chainChanged' event with payload", chainId);
				activate(injected);
			};
			const handleAccountsChanged = (accounts: string[]) => {
				log("Handling 'accountsChanged' event with payload", accounts);
				if (accounts.length > 0) {
					activate(injected);
				}
			};
			ethereum.on('connect', handleConnect);
			ethereum.on('chainChanged', handleChainChanged);
			ethereum.on('accountsChanged', handleAccountsChanged);
			return () => {
				if (ethereum.removeListener) {
					ethereum.removeListener('connect', handleConnect);
					ethereum.removeListener('chainChanged', handleChainChanged);
					ethereum.removeListener('accountsChanged', handleAccountsChanged);
				}
			};
		}
	}, [active, error, suppress, activate]);
}

export interface IEthereumContextValues {
	connect: () => void;
	logout: () => void;
	library: providers.Web3Provider;
	account: string | null | undefined;
	isConnectedToMetamask: boolean | undefined;
	errorMsg: string | null | undefined;
	nodeProvider: providers.Web3Provider;
	splitterContract: ethers.Contract;
	splitterContractNode: ethers.Contract;
	storageContract: ethers.Contract;
	storageContractNode: ethers.Contract;
	tokenContract: ethers.Contract;
	tokenContractNode: ethers.Contract;
	// contractState: IContractState;
	contracts: Record<string, Contract>;

	mainnetProvider: providers.Web3Provider;
	sharkTokenContractNode: ethers.Contract;
	sharkSplitterContractNode: ethers.Contract;
	sharkSplitterContractMM: ethers.Contract;
	dbitzTokenContractNode: ethers.Contract;
	dbitzSplitterContractNode: ethers.Contract;
	dbitzSplitterContractMM: ethers.Contract;
	holyTokenContractNode: ethers.Contract;
	holySplitterContractNode: ethers.Contract;
	holySplitterContractMM: ethers.Contract;
	realfaceTokenContractNode: ethers.Contract;
	realfaceSplitterContractNode: ethers.Contract;
	realfaceSplitterContractMM: ethers.Contract;
	cupidsTokenContractNode: ethers.Contract;
	cupidsSplitterContractNode: ethers.Contract;
	cupidsSplitterContractMM: ethers.Contract;
	dcaveBulovaTokenContractNode: ethers.Contract;
	dcaveBulovaSplitterContractNode: ethers.Contract;
	dcaveBulovaSplitterContractMM: ethers.Contract;						
	seaport: OpenSeaPort;
}

export interface IContractState {
	storageOwner: string | null;
	totalSupply: BigNumber | null;
	getMaxLength: BigNumber | null;
	storagePaused: boolean | null;
	tokenOwner: string | null;
	tokenPaused: boolean | null;
	getCommission: BigNumber | null;
	getPrice: BigNumber | null;
	baseURI: string | null;
	balanceOf: number;
	tokensInWallet: BigNumber[];
	lastMessage: string | null;
	lastTimestamp: number | null;
}

export const EthereumContext = createContext<Partial<IEthereumContextValues>>(
	{}
);

export function getErrorMessage(error: Error | any): string {
	log(`An error occurred:`);
	log(error);
	const { data } = error;
	if (typeof data === 'object') {
		const { code, message } = data;
		if (code && message) {
			error.code = code;
			error.message = message;
		}
	}
	const fallbackError = {
		code: 4999,
		message: 'An error occured. Check the console logs for more details (F12)',
	};
	error = serializeError(error, { fallbackError });

	if (error instanceof NoEthereumProviderError) {
		return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
	} else if (error instanceof UnsupportedChainIdError) {
		return "You're connected to an unsupported network.";
	} else if (
		error instanceof UserRejectedRequestErrorInjected ||
		error instanceof UserRejectedRequestErrorWalletConnect ||
		error instanceof UserRejectedRequestErrorFrame
	) {
		return 'Please authorize this website to access your Ethereum account.';
	} else {
		let msg = '';
		const messageFromCode = getMessageFromCode(error.code, 'Unknown error');
		log('getMessageFromCode:', messageFromCode);
		if (error.originalError) {
			log('Original error:');
			log(error.originalError);
		}
		log(error.code);
		switch (error.code) {
			case 'INSUFFICIENT_FUNDS':
			case -32000:
				msg = 'The connected wallet has insufficient funds to cover that tx 😟';
				break;
			case -32002:
				msg =
					'Metamask: Permission request already pending. Open MetaMask to continue!';
				break;
			case 4001:
				msg = 'Metamask: User denied transaction signature. ⛔';
				break;
			default:
				msg = error.message;
				log(`Error code ${error.code}: ${msg}`); //process other types
				break;
		}
		return msg;
	}
}

export const processError = (error: Error | any): void => {
	const msg = getErrorMessage(error);
	toast.error(`Error! ${msg}`);
};

export function getLibrary(
	provider:
		| ethers.providers.ExternalProvider
		| ethers.providers.JsonRpcFetchFunc
): providers.Web3Provider {
	const library = new providers.Web3Provider(provider);
	library.pollingInterval = 12000;
	return library;
}

export const EthereumProvider = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const [errorMsg, setErrorMsg] = useState<string | null>();
	const [isConnectedToMetamask, setIsConnected] =
		useState<boolean | undefined>(undefined);

	//activate the connection to our dedicated node
	const nodeContext = useWeb3React<providers.Web3Provider>('node');
	useEffect(() => {
		nodeContext.activate(maticNetwork);
		// eslint-disable-next-line
	}, []);

	//activate the connection to our dedicated node
	const mainnetNodeContext = useWeb3React<providers.Web3Provider>('mainnet');

	useEffect(() => {
		mainnetNodeContext.activate(mainnetNetwork);
		// eslint-disable-next-line
	}, []);

	const [seaport, setSeaport] = useState<OpenSeaPort | undefined>();
	useEffect(() => {
		if (mainnetNodeContext.library) {
			setSeaport(
				new OpenSeaPort(mainnetNodeContext.library, {
					networkName: Network.Main,
				})
			);
		}
		// eslint-disable-next-line
	}, [mainnetNodeContext.library]);

	const { connector, library, account, activate, deactivate, active, error } =
		useWeb3React<providers.Web3Provider>('metamask');
	const [activatingConnector, setActivatingConnector] =
		useState<InjectedConnector | undefined>();
	const triedEager = useEagerConnect();

	//error listeners
	useEffect(() => {
		if (error) {
			//keep the errors in one toast and keep updating it
			const toastId = 'single-error-message-toast';
			if (!toast.isActive(toastId)) {
				toast.error(getErrorMessage(error), {
					toastId,
				});
			} else {
				toast.update(toastId, {
					render: getErrorMessage(error),
					type: toast.TYPE.ERROR,
					autoClose: 2000,
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (error) {
			setErrorMsg(getErrorMessage(error));
		} else if (!active && !error) {
			setErrorMsg('Not connected to wallet.');
		} else {
			setErrorMsg(null);
		}
	}, [active, error]);

	useInactiveListener(!triedEager || !!activatingConnector);

	useEffect(() => {
		if (activatingConnector && activatingConnector === connector) {
			setActivatingConnector(undefined);
		}
	}, [activatingConnector, connector]);

	useEffect(() => {
		setIsConnected(!!(library && account));
	}, [library, account]);

	const connect = () => {
		// Metamask only for now
		setActivatingConnector(injected);
		activate(injected);
	};
	const logout = async () => {
		deactivate();
		injected.deactivate();
	};

	const splitterContract = useMemo(() => {
		return new ethers.Contract(
			msgsSplitterContractInformation.address,
			msgsSplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const splitterContractNode = useMemo(() => {
		return new ethers.Contract(
			msgsSplitterContractInformation.address,
			msgsSplitterContractInformation.abi,
			nodeContext.library
		);
	}, [nodeContext.library]);

	const storageContract = useMemo(() => {
		return new ethers.Contract(
			storageContractInformation.address,
			storageContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const storageContractNode = useMemo(() => {
		return new ethers.Contract(
			storageContractInformation.address,
			storageContractInformation.abi,
			nodeContext.library
		);
	}, [nodeContext.library]);

	const tokenContract = useMemo(() => {
		return new ethers.Contract(
			tokenContractInformation.address,
			tokenContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const tokenContractNode = useMemo(() => {
		return new ethers.Contract(
			tokenContractInformation.address,
			tokenContractInformation.abi,
			nodeContext.library
		);
	}, [nodeContext.library]);

	const sharkTokenContractNode = useMemo(() => {
		return new ethers.Contract(
			sharkzTokenContractInformation.address,
			sharkzTokenContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	const sharkSplitterContractNode = useMemo(() => {
		return new ethers.Contract(
			sharkzSplitterContractInformation.address,
			sharkzSplitterContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	const sharkSplitterContractMM = useMemo(() => {
		return new ethers.Contract(
			sharkzSplitterContractInformation.address,
			sharkzSplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const dbitzTokenContractNode = useMemo(() => {
		return new ethers.Contract(
			dbitzTokenContractInformation.address,
			dbitzTokenContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	const dbitzSplitterContractNode = useMemo(() => {
		return new ethers.Contract(
			dbitzSplitterContractInformation.address,
			dbitzSplitterContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	const dbitzSplitterContractMM = useMemo(() => {
		return new ethers.Contract(
			dbitzSplitterContractInformation.address,
			dbitzSplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const holyTokenContractNode = useMemo(() => {
		return new ethers.Contract(
			holyTokenContractInformation.address,
			holyTokenContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	const holySplitterContractNode = useMemo(() => {
		return new ethers.Contract(
			holySplitterContractInformation.address,
			holySplitterContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	console.log(holySplitterContractNode);

	const holySplitterContractMM = useMemo(() => {
		return new ethers.Contract(
			holySplitterContractInformation.address,
			holySplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const realfaceTokenContractNode = useMemo(() => {
		return new ethers.Contract(
			realfaceTokenContractInformation.address,
			realfaceTokenContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);	

	const realfaceSplitterContractNode = useMemo(() => {
		return new ethers.Contract(
			realfaceSplitterContractInformation.address,
			realfaceSplitterContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	console.log(holySplitterContractNode);

	const realfaceSplitterContractMM = useMemo(() => {
		return new ethers.Contract(
			realfaceSplitterContractInformation.address,
			realfaceSplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);


	const cupidsTokenContractNode = useMemo(() => {
		return new ethers.Contract(
			cupidsTokenContractInformation.address,
			cupidsTokenContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);	

	const cupidsSplitterContractNode = useMemo(() => {
		return new ethers.Contract(
			cupidsSplitterContractInformation.address,
			cupidsSplitterContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	console.log(holySplitterContractNode);

	const cupidsSplitterContractMM = useMemo(() => {
		return new ethers.Contract(
			cupidsSplitterContractInformation.address,
			cupidsSplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);

	const dcaveBulovaTokenContractNode = useMemo(() => {
		return new ethers.Contract(
			dcaveBulovaTokenContractInformation.address,
			dcaveBulovaTokenContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);	

	const dcaveBulovaSplitterContractNode = useMemo(() => {
		return new ethers.Contract(
			dcaveBulovaSplitterContractInformation.address,
			dcaveBulovaSplitterContractInformation.abi,
			mainnetNodeContext.library
		);
	}, [mainnetNodeContext.library]);

	console.log(holySplitterContractNode);

	const dcaveBulovaSplitterContractMM = useMemo(() => {
		return new ethers.Contract(
			dcaveBulovaSplitterContractInformation.address,
			dcaveBulovaSplitterContractInformation.abi,
			library?.getSigner()
		);
	}, [library]);	

	//create contract object for new version of eth-hooks
	const contracts: Record<string, Contract> = {};
	// contracts['storage'] = storageContract;
	// contracts['storageNode'] = storageContractNode;
	// contracts['token'] = tokenContract;
	// contracts['tokenNode'] = tokenContractNode;

	//Track the state of the contract items we are interested in
	// const [contractState, setContractState] = useState<IContractState>({
	// 	totalSupply: null,
	// 	storageOwner: null,
	// 	getMaxLength: null,
	// 	storagePaused: null,
	// 	tokenOwner: null,
	// 	tokenPaused: null,
	// 	getCommission: null,
	// 	getPrice: null,
	// 	baseURI: null,
	// 	balanceOf: 0,
	// 	tokensInWallet: [],
	// 	lastMessage: null,
	// 	lastTimestamp: null,
	// });

	// //Set the state of a particular contract item
	// function setContractStateKV(key: string, value: any) {
	// 	setContractState((prevState: any) => {
	// 		return {
	// 			...prevState,
	// 			[key]: value,
	// 		};
	// 	});
	// }

	// //Function to get and update contract data state
	// async function updateFixedState() {
	// 	if (tokenContractNode && nodeContext.library) {
	// 		try {
	// 			//Storage Params
	// 			const storageOwner: string = await storageContractNode.owner();
	// 			const getMaxLength: BigNumber =
	// 				await storageContractNode.getMaxLength();
	// 			const storagePaused: boolean = await storageContractNode.paused();
	// 			//Token/721 Params
	// 			const tokenOwner: string = await tokenContractNode.owner();
	// 			const tokenPaused: boolean = await tokenContractNode.paused();
	// 			const getCommission: BigNumber =
	// 				await tokenContractNode.getCommission();
	// 			const getPrice: BigNumber = await tokenContractNode.getPrice();
	// 			const baseURI: string = await tokenContractNode.baseURI();
	// 			setContractState((prevState: any) => {
	// 				return {
	// 					...prevState,
	// 					storageOwner,
	// 					getMaxLength,
	// 					storagePaused,
	// 					tokenOwner,
	// 					getCommission,
	// 					getPrice,
	// 					tokenPaused,
	// 					baseURI,
	// 				};
	// 			});
	// 			// console.log('Updated state');
	// 		} catch (err: any) {
	// 			// console.log(`${err.message}`, err);
	// 		}
	// 	}
	// }

	// //Sync only the initial state of the fixed contract calls above
	// useEffect(() => {
	// 	updateFixedState();
	// }, [tokenContractNode && nodeContext.library]);

	// //Function to get and update contract data state
	// async function updateState() {
	// 	if (tokenContractNode && nodeContext.library) {
	// 		try {
	// 			const tokenPaused: boolean = await tokenContractNode.paused();
	// 			const totalSupply: BigNumber = await tokenContractNode.totalSupply();
	// 			setContractState((prevState: any) => {
	// 				return {
	// 					...prevState,
	// 					totalSupply,
	// 					tokenPaused,
	// 				};
	// 			});
	// 			// console.log('Updated state');
	// 		} catch (err: any) {
	// 			// console.log(`${err.message}`, err);
	// 		}
	// 	}
	// }

	// //Effect to update the initial state, as we added a pollTime of 25 seconds
	// useEffect(() => {
	// 	updateState();
	// }, [tokenContractNode && nodeContext.library]);

	// //Update the state on every block
	// useOnRepetition(
	// 	() => {
	// 		updateState();
	// 		updateBalanceOf();
	// 	},
	// 	{ provider: nodeContext.library, pollTime: 25000 }
	// );

	// //seperate balanceOf calls since account value is not ready right away
	// async function updateBalanceOf() {
	// 	if (tokenContractNode && nodeContext.library && account) {
	// 		try {
	// 			//check if account has balance
	// 			const balanceOf: number = (
	// 				await tokenContractNode.balanceOf(account)
	// 			).toNumber();
	// 			setContractStateKV('balanceOf', balanceOf);

	// 			//get the last message sent
	// 			const [lastMessage, lastTimestamp]: [
	// 				lastMessage: string,
	// 				lastTimestamp: BigNumber
	// 			] = await storageContractNode.retrieve(account);
	// 			setContractStateKV('lastMessage', lastMessage);
	// 			setContractStateKV('lastTimestamp', lastTimestamp);
	// 			// //if so then get the token IDs
	// 			// let tokensInWallet: BigNumber[] = [];
	// 			// if (balanceOf && balanceOf > 0) {
	// 			// 	tokensInWallet = await tokenContractNode.tokensInWallet(account);
	// 			// }
	// 			// setContractStateKV('tokensInWallet', tokensInWallet);
	// 		} catch (err: any) {
	// 			console.log(err);
	// 		}
	// 	}
	// }

	// //make sure balanceOf updates when account finally loads
	// useEffect(() => {
	// 	updateBalanceOf();
	// }, [tokenContractNode, nodeContext.library, account]);

	return (
		<EthereumContext.Provider
			value={{
				connect,
				logout,
				library,
				account,
				isConnectedToMetamask,
				errorMsg,
				nodeProvider: nodeContext.library,
				storageContract,
				storageContractNode,
				splitterContract,
				splitterContractNode,
				tokenContract,
				tokenContractNode,
				// contractState,
				contracts,
				mainnetProvider: mainnetNodeContext.library,
				sharkTokenContractNode,
				sharkSplitterContractNode,
				sharkSplitterContractMM,
				dbitzTokenContractNode,
				dbitzSplitterContractNode,
				dbitzSplitterContractMM,
				holyTokenContractNode,
				holySplitterContractNode,
				holySplitterContractMM,
				realfaceTokenContractNode,
				realfaceSplitterContractNode,
				realfaceSplitterContractMM,				
				cupidsTokenContractNode,
				cupidsSplitterContractNode,
				cupidsSplitterContractMM,				
				dcaveBulovaTokenContractNode,
				dcaveBulovaSplitterContractNode,
				dcaveBulovaSplitterContractMM,					
				seaport,
			}}
		>
			{children}
		</EthereumContext.Provider>
	);
};
