import React from 'react';
import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';
import { IconType } from 'react-icons';
import { constants, colors, useTheme } from '../index';

const SidebarContainer = styled.aside`
	opacity: ${({ sidebarVisible }: { sidebarVisible: boolean }) =>
		sidebarVisible ? 1 : 0};
	visibility: ${({ sidebarVisible }: { sidebarVisible: boolean }) =>
		sidebarVisible ? null : 'hidden'};
	/* background-color: ${({ theme }: { theme: string }) =>
		theme === 'dark'
			? colors.background.nav.dark
			: colors.background.nav.light}; */
	background-color: white;
	position: fixed;
	width: 250px;
	height: 100vh;
	right: 0;
	top: 0;
	z-index: 999;
	border-left: 1px solid
		${({ theme }: { theme: string }) =>
			theme !== 'dark' ? colors.borders.dark : colors.borders.light};
`;

const Logo = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.nav.dark : colors.text.nav.light};
	cursor: pointer;
	text-align: center;
	z-index: 999;
`;
const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Menu = styled.div`
	position: relative;
	z-index: 999;
`;

const MenuItemElem = styled.div`
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid
		${({ theme }: { theme: string }) => (theme !== 'dark' ? '#ccc' : '#000')};
	background-color: #222;
	color: white;
	opacity: 0.7;
	&:hover {
		background-color: #444;
		opacity: 0.95;
	}
`;

const Overlay = styled.div`
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	filter: blur(1rem);
	opacity: 0.6;
`;

const Wrap = styled.div`
	position: relative;
`;

export const Link = styled(RLink)`
	background-color: ${({ theme }: { theme: string }) =>
		theme === 'dark'
			? colors.background.nav.dark
			: colors.background.nav.light};
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.nav.dark : colors.text.nav.light};
	text-decoration: none;
	font-weight: 900;
	&:hover {
		text-decoration: none;
		color: ${({ theme }: { theme: string }) =>
			theme === 'dark' ? 'black' : 'white'};
	}
`;

interface IMenuItem {
	path: string;
	title: string;
	icon: IconType;
	onClick: () => void;
}

export const MenuItem: React.FC<IMenuItem> = ({
	path,
	title,
	icon,
	onClick,
	...rest
}) => {
	const { theme } = useTheme()!;
	const Icon = icon;
	return (
		<Link to={path} onClick={onClick}>
			<MenuItemElem theme={theme} {...rest}>
				<Icon /> {title}
			</MenuItemElem>
		</Link>
	);
};

export const SidebarLogo = styled.img`
	margin: 0;
	z-index: 1;
	width: 140px;
	height: auto;
`;

interface ISidebar {
	sidebarVisible: boolean;
	toggleSidebar: () => void;
}

const Title = styled.h1`
	font-family: 'Archivo Black', sans-serif;
`;

export const Sidebar: React.FunctionComponent<ISidebar> = ({
	sidebarVisible,
	toggleSidebar,
}) => {
	const { theme } = useTheme()!;
	return (
		<Wrap>
			{sidebarVisible && <Overlay onClick={toggleSidebar}></Overlay>}
			<SidebarContainer sidebarVisible={sidebarVisible} theme={theme}>
				<Logo onClick={toggleSidebar} theme={theme}>
					<LogoWrapper>
						<Title>{constants.projectName}</Title>
					</LogoWrapper>
				</Logo>
				<Menu>
					{constants.navMenu.length &&
						constants.navMenu
							.filter(item => item.include.indexOf('sidebar') > -1)
							.map((item, index) => {
								return (
									<MenuItem
										key={`menu-item-${index}`}
										path={item.path}
										title={item.text}
										icon={item.icon}
										onClick={toggleSidebar}
									/>
								);
							})}
				</Menu>
			</SidebarContainer>
		</Wrap>
	);
};
