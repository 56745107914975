import React from 'react';
import styled from 'styled-components';
import { FlexColumn, LazyImage } from '../components';
import { colors, useTitle, AltButton, constants } from '../index';
import ReactScrollDetect, { DetectSection } from 'react-scroll-detect';
import img_sharkz from '../../images/sharkz.png';
import img_dbitz from '../../images/dbitz.png';
import img_msgs from '../../images/msgs.png';

const ConnectPageSection = styled.div`
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;

	background: rgb(131, 58, 180);
	background: linear-gradient(
		90deg,
		rgba(131, 58, 180, 1) 0%,
		rgba(29, 253, 235, 1) 50%,
		rgba(252, 176, 69, 1) 100%
	);
	display: flex;
	flex: 1;
	padding: 45px;
	width: 100%;
	transition: 0.4s ease all;
	justify-content: center;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

const ConnectFrame = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 400px;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
`;
const Div = styled.div`
	transition: 0.5s ease-in-out all;
`;

const ConnectTitleFrame = styled.div``;
const ConnectTitle = styled.h1`
	font-family: 'Archivo Black', sans-serif;
	text-shadow: 2px 2px 10px #000000, 2px 2px 10px #000000, 2px 2px 10px #000000;
	letter-spacing: 2px;
	text-align: center;
	color: white;
`;
const RegText = styled.span`
	font-family: 'Rubik', sans-serif;
`;

interface IFeature {
	title: string;
	subtitle: string;
	body: string;
	imageSrc: any;
	buttonText?: any;
	buttonURL?: string;
}

const features: IFeature[] = [
	{
		title: 'Snarky Sharkz Society',
		subtitle: 'NFT Project',
		body: `For charity.`,
		imageSrc: img_sharkz,
		buttonText: `Go to Mint Site`,
		buttonURL: `https://snarkysharkz.app`,
	},
	{
		title: 'Degenibitz',
		subtitle: 'NFT Project',
		body: `For charity.`,
		imageSrc: img_dbitz,
		buttonText: `Go to Mint Site`,
		buttonURL: `https://mint.dbitz.xyz`,
	},
	{
		title: 'msgs.eth',
		subtitle: 'NFT Project',
		body: `For fun.`,
		imageSrc: img_msgs,
		buttonText: `Coming Soon!`,
		buttonURL: `#`,
	},
];

export const Home: React.FunctionComponent = () => {
	useTitle({ page: 'Home' });
	const [cur, handlePageChange] = React.useState(0);
	return (
		<FlexColumn
			style={{ alignItems: 'center', flex: 1, backgroundColor: '#eeeeee' }}
		>
			<ConnectPageSection className='noselect'>
				<ConnectFrame>
					<ConnectTitleFrame>
						<ConnectTitle>
							<RegText></RegText> <b>{constants.projectName}</b>
						</ConnectTitle>
					</ConnectTitleFrame>
				</ConnectFrame>
			</ConnectPageSection>

			<div className='container'>
				<main role='main'>
					<ReactScrollDetect triggerPoint='top' onChange={handlePageChange}>
						{features.map((feature: any, index: number) => {
							return (
								<React.Fragment key={`feature-${index}`}>
									<DetectSection>
										<div className='row featurette py-5 d-flex align-items-center'>
											<div
												className={`p-5 col-md-7 ${
													index % 2 ? 'order-md-2' : ''
												}`}
											>
												<h2 className='featurette-heading'>
													{feature.title}{' '}
													<span className='text-muted'>{feature.subtitle}</span>
												</h2>
												<p className='lead'>{feature.body}</p>
												{feature.buttonText ? (
													<a href={feature.buttonURL} target={'_BLANK'}>
														<AltButton style={{ maxWidth: 200 }}>
															{feature.buttonText}
														</AltButton>
													</a>
												) : (
													<></>
												)}
											</div>
											<Div
												className={`p-53 col-md-5 ${
													index % 2 ? 'order-md-1' : ''
												}`}
											>
												<LazyImage
													className='featurette-image img-fluid mx-auto'
													data-src='holder.js/500x500/auto'
													alt='Generic placeholder image'
													src={feature.imageSrc}
													cur={cur}
													index={index}
												/>
											</Div>
										</div>
										{index < features.length - 1 ? (
											<hr className='featurette-divider' />
										) : (
											<></>
										)}
									</DetectSection>
								</React.Fragment>
							);
						})}
					</ReactScrollDetect>
				</main>
			</div>
		</FlexColumn>
	);
};
