import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '../index';
import discord from '../../images/dc.png';
import twitter from '../../images/tw.png';
import quicknode from '../../images/quicknode.png';
import atlascorp from '../../images/atlascorp.png';
import { constants } from '../index';
const FooterNav = styled.nav`
	background-color: white;
	/* background-color: ${({ theme }: { theme: string }) =>
		theme === 'dark'
			? colors.background.nav.dark
			: colors.background.nav.light}; */
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.nav.dark : colors.text.nav.light};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.4s ease all;
	min-height: 70px;
`;

const FooterSocialIcon = styled.img`
	width: 35px;
	height: auto;
	margin: 0px 5px;
`;

const Link = styled(motion.a)`
	// width: 35px;
	// height: auto;
	margin: 0px 7px;
`;

const QuicknodeImg = styled.img`
	height: auto;
	width: 125px;
`;
const AtlasCorpImg = styled.img`
	height: auto;
	width: 175px;
`;

const FooterCol = styled.div`
	margin-top: 12px;
	margin-bottom: 12px;
	display: flex;
`;

const FlexCenter = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

export const Footer: React.FunctionComponent = () => {
	return (
		<FooterNav className='noselect'>
			<div className='container' style={{ margin: 20 }}>
				<div className='row text-center w-100 py-xs-3'>
					<FooterCol className='col-12 col-lg-4 order-lg-first'>
						<FlexCenter>
							<span>
								Powered by{' '}
								<Link
									href={`https://www.quicknode.com/`}
									whileTap={{ scale: 0.8 }}
									whileHover={{ scale: 1.15 }}
									target='_BLANK'
									rel='noreferrer'
								>
									<QuicknodeImg src={quicknode} alt='QuickNode' />
								</Link>
							</span>
						</FlexCenter>
					</FooterCol>
					<FooterCol className='col-12 col-lg-4 order-first my-sm-2'>
						<FlexCenter>
							<Link
								href={`https://discord.gg/${constants.socials.discord}`}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
								target='_BLANK'
								rel='noreferrer'
							>
								<FooterSocialIcon src={discord} alt={`Discord`} />
							</Link>

							<Link
								href={`https://twitter.com/${constants.socials.twitter}`}
								whileTap={{ scale: 0.8 }}
								whileHover={{ scale: 1.15 }}
								target='_BLANK'
								rel='noreferrer'
							>
								<FooterSocialIcon src={twitter} alt={`Twitter`} />
							</Link>
						</FlexCenter>
					</FooterCol>
					<FooterCol className='order-last col-lg-4 col-sm-12 col-12'>
						<FlexCenter>
							<span>
								Developed in 2021 by
								<Link
									href={`https://discord.gg/${constants.socials.atlasDiscord}`}
									whileTap={{ scale: 0.8 }}
									whileHover={{ scale: 1.15 }}
									target='_BLANK'
									rel='noreferrer'
								>
									<AtlasCorpImg src={atlascorp} alt='Atlas Corporation' />
								</Link>
							</span>
						</FlexCenter>
					</FooterCol>
				</div>
			</div>
		</FooterNav>
	);
};
