import { AiFillHome, AiFillInfoCircle } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { BsLink, BsImageFill } from 'react-icons/bs';
import { MdTextsms } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import {
	FaRegLightbulb,
	FaLightbulb,
	FaDiscord,
	FaTwitterSquare,
	FaSpinner,
} from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
export const icons = {
	AiFillHome,
	AiFillInfoCircle,
	BiError,
	FaRegLightbulb,
	FaLightbulb,
	FaSpinner,
	FaDiscord,
	FaTwitterSquare,
	GiHamburgerMenu,
	FiSend,
	BsLink,
	MdTextsms,
	BsImageFill,
};
