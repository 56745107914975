import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink as RLink } from 'react-router-dom';
import { constants, Container, colors, useTheme, icons } from '../index';
import atlas_logo from '../../images/logo.png';
const Title = styled.h1`
	font-family: 'Archivo Black', sans-serif;
	display: flex;
	align-items: center;
	margin-left: 20px;
	margin-right: auto;
	@media screen and (max-width: 600px) {
		font-size: 1.25rem;
	}
`;

const Nav = styled.nav`
	position: relative;
	height: 100px;
	background-color: white;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.nav.dark : colors.text.nav.light};
	border-bottom: 1px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
	display: flex;
	justify-content: center;
	transition: 0.4s ease all;
`;

const NavMenu = styled.ul`
	margin: auto;
	list-style: none;
	white-space: nowrap;
	padding-right: 30px;
	@media screen and (max-width: 520px) {
		display: none;
	}
`;

const NavMenuItem = styled(motion.li)`
	padding: 0px 8px 0px 8px;
	display: inline-block;
	float: none;
	font-size: 0.9rem;
	@media screen and (max-width: 600px) {
		font-size: 1rem;
	}
`;

const MenuLink = styled(RLink)`
	text-decoration: none;
	color: black;
	display: flex;
	flex-direction: row;
	&:hover {
		color: #aaa;
		/* transform: translateY(-2px); */
	}
`;

const HamburgerIconContainer = styled.div`
	display: none;
	@media screen and (max-width: 520px) {
		display: block;
	}
	font-size: 16px;
	padding: 10px;
	cursor: pointer;
`;

const NavbarIconsContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-right: 30px;
	align-items: center;
`;

const StyledIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
	margin-bottom: 2px;
`;
const Img = styled.img`
	display: flex;
	align-items: center;
	max-width: 70px;
	margin-right: 10px;
`;

const HamburgerIcon = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
	const Icon = icons.GiHamburgerMenu;
	return (
		<HamburgerIconContainer onClick={toggleSidebar}>
			<Icon />
		</HamburgerIconContainer>
	);
};

interface INavbar {
	sidebarVisible: boolean;
	toggleSidebar: () => void;
}

export const Navbar = ({
	sidebarVisible,
	toggleSidebar,
}: INavbar): JSX.Element => {
	const { theme } = useTheme()!;
	return (
		<Nav theme={theme}>
			<Container>
				<Title>
					<Img
						className='icon-spin'
						src={atlas_logo}
						alt={`${constants.projectName} logo`}
					/>
					{constants.projectName}
				</Title>
				<div
					style={{
						display: 'flex',
					}}
				>
					<NavbarIconsContainer>
						<HamburgerIcon toggleSidebar={toggleSidebar} />
					</NavbarIconsContainer>
					<NavMenu>
						{!sidebarVisible &&
							constants.navMenu.length &&
							constants.navMenu
								.filter(item => item.include.indexOf('navbar') > -1)
								.map((c, i) => {
									const Icon = c.icon;
									return (
										<NavMenuItem
											whileTap={{ scale: 0.8 }}
											whileHover={{ scale: 1.2 }}
											key={`nav-menu-${i}`}
										>
											<MenuLink
												{...(c.path === '/' ? { exact: true } : {})}
												theme={theme}
												to={c.path}
												activeClassName={'navbar-link-active'}
												className={'noselect'}
											>
												<StyledIcon>
													<Icon />
												</StyledIcon>{' '}
												{c.text}
											</MenuLink>
										</NavMenuItem>
									);
								})}
					</NavMenu>
				</div>
			</Container>
		</Nav>
	);
};
