export const INFURA_ID = 'ab43e0be78e647aa860243b11b118727';
export const ETHERSCAN_KEY = 'FG594GV7NNQV16K6WJC3E3RTMQXC3Y222R';

interface NetworkList {
	[key: string]: INetwork;
}

interface INetwork {
	name: string;
	color: string;
	chainId: number;
	blockExplorer: string;
	rpcUrl: string;
	faucet?: string;
	price?: number;
	gasPrice?: number;
	opensea?: string;
}

export const NETWORKS: NetworkList = {
	localhost: {
		name: 'localhost',
		color: '#666666',
		chainId: 31337,
		blockExplorer: '',
		rpcUrl: 'http://' + window.location.hostname + ':8545',
	},
	mainnet: {
		name: 'mainnet',
		color: '#ff8b9e',
		chainId: 1,
		rpcUrl: `https://cold-wispy-firefly.quiknode.pro/efe16c65e7bdd497c2a34cdef175db8385bed823/`,
		// rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
		blockExplorer: 'https://etherscan.io/',
		opensea: 'https://opensea.io/',
	},
	kovan: {
		name: 'kovan',
		color: '#7003DD',
		chainId: 42,
		rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
		blockExplorer: 'https://kovan.etherscan.io/',
		faucet: 'https://gitter.im/kovan-testnet/faucet', // https://faucet.kovan.network/
	},
	rinkeby: {
		name: 'rinkeby',
		color: '#e0d068',
		chainId: 4,
		rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
		faucet: 'https://faucet.rinkeby.io/',
		blockExplorer: 'https://rinkeby.etherscan.io/',
		opensea: 'https://testnets.opensea.io/',
	},
	ropsten: {
		name: 'ropsten',
		color: '#F60D09',
		chainId: 3,
		faucet: 'https://faucet.ropsten.be/',
		blockExplorer: 'https://ropsten.etherscan.io/',
		rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
	},
	goerli: {
		name: 'goerli',
		color: '#0975F6',
		chainId: 5,
		faucet: 'https://goerli-faucet.slock.it/',
		blockExplorer: 'https://goerli.etherscan.io/',
		rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
	},
	xdai: {
		name: 'xdai',
		color: '#48a9a6',
		chainId: 100,
		price: 1,
		gasPrice: 1000000000,
		rpcUrl: 'https://dai.poa.network',
		faucet: 'https://xdai-faucet.top/',
		blockExplorer: 'https://blockscout.com/poa/xdai/',
	},
	matic: {
		name: 'matic',
		color: '#2bbdf7',
		chainId: 137,
		price: 1,
		gasPrice: 1000000000,
		rpcUrl:
			'https://nameless-weathered-feather.matic.quiknode.pro/9b5ab369a9cf5af7d42ecad8b57952ed58f70b1d/',
		// publicRpcUrl: 'https://rpc-mainnet.maticvigil.com',
		faucet: 'https://faucet.matic.network/',
		blockExplorer: 'https://polygonscan.com/',
	},
	mumbai: {
		name: 'mumbai',
		color: '#92D9FA',
		chainId: 80001,
		price: 1,
		gasPrice: 1000000000,
		// rpcUrl: 'https://rpc-mumbai.maticvigil.com',
		rpcUrl:
			'https://quiet-fragrant-flower.matic-testnet.quiknode.pro/752c376c3164f58990c50845e96119740bf8d266/',
		faucet: 'https://faucet.matic.network/',
		blockExplorer: 'https://mumbai.polygonscan.com/',
		opensea: 'https://testnets.opensea.io/',
	},
	localArbitrum: {
		name: 'localArbitrum',
		color: '#50a0ea',
		chainId: 153869338190755,
		blockExplorer: '',
		rpcUrl: `http://localhost:8547`,
	},
	localArbitrumL1: {
		name: 'localArbitrumL1',
		color: '#50a0ea',
		chainId: 44010,
		blockExplorer: '',
		rpcUrl: `http://localhost:7545`,
	},
	rinkebyArbitrum: {
		name: 'Arbitrum Testnet',
		color: '#50a0ea',
		chainId: 421611,
		blockExplorer: 'https://rinkeby-explorer.arbitrum.io/#/',
		rpcUrl: `https://rinkeby.arbitrum.io/rpc`,
	},
	arbitrum: {
		name: 'Arbitrum',
		color: '#50a0ea',
		chainId: 42161,
		blockExplorer: 'https://explorer.arbitrum.io/#/',
		rpcUrl: `https://arb1.arbitrum.io/rpc`,
		gasPrice: 0,
	},
	localOptimismL1: {
		name: 'localOptimismL1',
		color: '#f01a37',
		chainId: 31337,
		blockExplorer: '',
		rpcUrl: 'http://' + window.location.hostname + ':9545',
	},
	localOptimism: {
		name: 'localOptimism',
		color: '#f01a37',
		chainId: 420,
		blockExplorer: '',
		rpcUrl: 'http://' + window.location.hostname + ':8545',
		gasPrice: 0,
	},
	kovanOptimism: {
		name: 'kovanOptimism',
		color: '#f01a37',
		chainId: 69,
		blockExplorer: 'https://kovan-optimistic.etherscan.io/',
		rpcUrl: `https://kovan.optimism.io`,
		gasPrice: 0,
	},
	optimism: {
		name: 'optimism',
		color: '#f01a37',
		chainId: 10,
		blockExplorer: 'https://optimistic.etherscan.io/',
		rpcUrl: `https://mainnet.optimism.io`,
	},
};

export const NETWORK = (chainId: number): INetwork | undefined => {
	for (const n in NETWORKS) {
		if (NETWORKS[n].chainId === chainId) {
			return NETWORKS[n];
		}
	}
};
