import { useEffect, useState, FC, ReactElement } from 'react';
import { icons } from '../index';
import { useInView } from 'react-hook-inview';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Img = styled(motion.img)``;

type ChildProps = {
	src: string;
	alt: string;
	className?: string;
	cur?: number;
	index?: number;
};
export const LazyImage: FC<ChildProps> = ({
	src,
	alt,
	className,
	cur,
	index,
}): ReactElement => {
	const [loaded, setLoaded] = useState<boolean>(false);

	const handleLoad = () => {
		setLoaded(true);
	};

	const [ref, inView] = useInView();
	const inViewAndAbove =
		typeof index === 'number' &&
		index >= 0 &&
		typeof cur === 'number' &&
		cur >= 0
			? index <= cur || inView
			: inView;
	useEffect(() => {
		const img = new Image();
		img.onload = handleLoad.bind(this);
		img.src = src!;
	}, []);

	const FaSpinner = icons.FaSpinner;
	return loaded ? (
		<Img
			ref={ref}
			alt={alt}
			src={inViewAndAbove ? src : ''}
			className={className ? className : ''}
			animate={{
				x: inViewAndAbove ? 0 : 200,
				opacity: inViewAndAbove ? 1 : 0,
			}}
			transition={{
				opacity: { delay: 0.5, duration: 0.6 },
			}}
		/>
	) : (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<span>
				<FaSpinner className='icon-spin' style={{ marginRight: 5 }} />
			</span>
		</div>
	);
};

export const LazyImageCardTop: FC<ChildProps> = ({
	src,
	alt,
}): ReactElement => {
	const [loaded, setLoaded] = useState<boolean>(false);

	const handleLoad = () => {
		setLoaded(true);
	};

	useEffect(() => {
		const img = new Image();
		img.onload = handleLoad.bind(this);
		img.src = src!;
	}, []);

	const FaSpinner = icons.FaSpinner;
	return loaded ? (
		<a href={src} target='_BLANK'>
			<img
				className='card-img-top'
				alt={alt}
				src={src}
				style={{ border: 'none' }}
			/>
		</a>
	) : (
		<div
			style={{
				width: '285px',
				height: '285px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<span>
				<FaSpinner className='icon-spin' style={{ marginRight: 5 }} />
				&nbsp;Loading...
			</span>
		</div>
	);
};
