import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { slideInLeft, slideInUp } from 'react-animations';
import { useTheme, colors } from '../index';
import { Link as RLink } from 'react-router-dom';
export const topLogoAnim = keyframes`${slideInLeft}`;
export const bottomLogoAnim = keyframes`${slideInUp}`;

export const Container = styled.div`
	width: 1200px;
	transition: 0.2s ease all;
	@media screen and (max-width: 1200px) {
		width: 100%;
	}
	display: flex;
`;

export const Section = styled.section`
	display: flex;
	padding: 45px;
	min-height: 300px;
	width: 100%;
	border-bottom: 1px solid
		${({ theme }: { theme: string }) =>
			theme === 'dark' ? colors.borders.light : colors.borders.dark};
	justify-content: center;
	transition: 0.4s ease all;
	background-color: ${({ theme }: { theme: string }) =>
		theme === 'dark'
			? colors.background.section.dark
			: colors.background.section.light};
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

export const InnerSection = styled.div`
	width: 710px;
	transition: 0.2s ease all;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 700px) {
		width: 100%;
	}
`;

export const H1 = styled.h1`
	font-family: 'Archivo Black', sans-serif;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 1.25px;
	transition: 1s ease all;
	@media screen and (max-width: 600px) {
		font-size: 1.5rem;
		letter-spacing: 1px;
	}
`;

export const Paragraph = styled.p``;

interface IButtonProps {
	children?: React.ReactNode;
	props?: any;
	type?: any;
	onClick?: any;
	className?: any;
	whileHover?: any;
	whileTap?: any;
	style?: any;
	disabled?: boolean | null;
}

export const ButtonElem = styled(motion.button)`
	border-radius: 8px;
	padding: 10px 20px;
	width: 85%;
	background-color: #fcb045;
	color: white;
	border: 0;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
	&:hover {
		background-color: #833ab4;
	}
	&:disabled {
		cursor: not-allowed;
		pointer-events: all !important;
	}
`;

export const Button: React.FC<IButtonProps> = ({
	children,
	onClick,
	whileHover,
	whileTap,
	disabled,
	style,
	type,
	...props
}) => {
	const { theme } = useTheme()!;
	return (
		<ButtonElem
			theme={theme}
			whileHover={whileHover ? whileHover : { scale: 1.15 }}
			whileTap={whileTap ? whileTap : { scale: 0.85 }}
			disabled={disabled ? disabled : false}
			style={style ? style : {}}
			onClick={onClick}
			type={type ? type : 'button'}
			{...props}
		>
			{children}
		</ButtonElem>
	);
};
export const AltButtonElem = styled(motion.button)`
	border-radius: 8px;
	padding: 10px 20px;
	width: 85%;
	background-color: #833ab4;
	color: white;
	border: 0;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
	&:hover {
		background-color: #fcb045;
	}
	&:disabled {
		cursor: not-allowed;
		pointer-events: all !important;
	}
`;
export const AltButton: React.FC<IButtonProps> = ({
	children,
	onClick,
	whileHover,
	whileTap,
	disabled,
	style,
	...props
}) => {
	const { theme } = useTheme()!;
	return (
		<AltButtonElem
			theme={theme}
			whileHover={whileHover ? whileHover : { scale: 1.15 }}
			whileTap={whileTap ? whileTap : { scale: 0.85 }}
			disabled={disabled ? disabled : false}
			style={style ? style : {}}
			onClick={onClick}
			{...props}
		>
			{children}
		</AltButtonElem>
	);
};

export const Input = styled(motion.input)`
	border-radius: 8px;
	padding: 10px 20px;
	width: 85%;
	background-color: #222222;
	color: white;
	border: 0;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
	&:hover {
		background-color: #ab366e;
	}
	&:disabled {
		cursor: not-allowed;
		pointer-events: all !important;
	}
`;

export const UnstyledLink = styled(RLink)`
	text-decoration: none;
`;

const DividerElem = styled.div`
	transition: 0.5s ease-in-out all;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding-left: 45px;
	padding-right: 45px;
	margin-bottom: 5px;
	@media screen and (max-width: 600px) {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

export const Divider = ({
	title,
	color,
	shadow,
}: {
	title: string;
	color?: string;
	shadow?: boolean;
}): JSX.Element => {
	return (
		<DividerElem>
			<div style={{ height: '2px', flex: 1, backgroundColor: '#eee' }}></div>
			<h5
				style={{
					marginTop: 10,
					color: color ? color : 'black',
					fontFamily: "'Archivo Black', sans-serif",
					textShadow: shadow
						? '3px 1px 3px #000000, 3px 1px 12px #000000'
						: 'none',
				}}
			>
				{title}
			</h5>
			<div style={{ height: '2px', flex: 1, backgroundColor: '#eee' }}></div>
		</DividerElem>
	);
};
