import { createGlobalStyle } from 'styled-components';
import { useTheme, colors } from '../index';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Rubik', sans-serif;
    margin: 0px;
    overflow-X: hidden;
    transition: all 4s ease 0;
    background:#3d3d3d;
    /* background-color: ${({ theme }: { theme: string }) =>
			theme === 'light'
				? colors.background.section.light
				: colors.background.section.dark}; */
  }
  .text-center {
    text-align: center;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .navbar-link-active {
    border-bottom: 4px solid #ccc;
  }
  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;

export const Global: React.FunctionComponent = () => {
	const { theme } = useTheme()!;
	return <GlobalStyle theme={theme} />;
};
