import React, { useState, useContext } from 'react';
import {
	TopAlert,
	HolyGrail,
	HolyGrailBody,
	Navbar,
	Sidebar,
	Footer,
	AnimatedRoutes,
	RouteTransition,
	NoMatch404,
	EthereumContext,
	Connect,
	Home,
	Projects,
} from './common';

const App: React.FunctionComponent = () => {
	const [sidebarVisible, toggleSidebar] = useState(false);
	const toggle = () => {
		toggleSidebar(!sidebarVisible);
	};
	const { isConnectedToMetamask } = useContext(EthereumContext);
	return (
		<React.Fragment>
			<Sidebar sidebarVisible={sidebarVisible} toggleSidebar={toggle} />
			<HolyGrail>
				<Navbar sidebarVisible={sidebarVisible} toggleSidebar={toggle} />
				<TopAlert />
				<HolyGrailBody>
					<AnimatedRoutes exitBeforeEnter initial={false}>
						<RouteTransition exact path='/' slide={35} slideUp={0}>
							<Home />
						</RouteTransition>
						<RouteTransition exact path='/projects' slide={35} slideUp={0}>
							{!isConnectedToMetamask ? <Connect /> : <Projects />}
						</RouteTransition>
						<RouteTransition exact path={undefined} slide={35} slideUp={0}>
							<NoMatch404 />
						</RouteTransition>
					</AnimatedRoutes>
				</HolyGrailBody>
				<Footer />
			</HolyGrail>
		</React.Fragment>
	);
};

export default App;
