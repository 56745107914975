import React from 'react';
import { useCookies } from 'react-cookie';
import { constants } from '../constants';

const cookieName = `${constants.cookiePrefix}-theme`;

export const lightTheme = 'light';
export const darkTheme = 'dark';

export type ThemeContextType = {
	theme: string;
	toggleTheme: () => void;
};

export const ThemeContext = React.createContext<ThemeContextType | undefined>(
	undefined
);

type Props = {
	children: React.ReactNode;
};
export const ThemeProvider = ({ children }: Props): JSX.Element => {
	const [cookies, setCookie] = useCookies([cookieName]);
	const [theme, setTheme] = React.useState(cookies[cookieName] || 'light');
	const toggleTheme = () => {
		const set = theme === 'light' ? 'dark' : 'light';
		setTheme(set);
		setCookie(cookieName, set);
	};
	React.useEffect(() => {
		const currentTheme = cookies[cookieName] || 'light';
		setTheme(currentTheme);
	}, [cookies]);
	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};

export const useTheme = (): ThemeContextType | undefined =>
	React.useContext(ThemeContext);
