import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import {
	colors,
	EthereumContext,
	useTitle,
	ExternalLink,
	NETWORK,
	processError,
} from '../index';
import { ethers, providers } from 'ethers';
import { AiFillPicture } from 'react-icons/ai';
import { MdMoneyOff } from 'react-icons/md';
import { ImBoxRemove } from 'react-icons/im';
import { OpenSeaPort } from 'opensea-js';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
const ConnectPageSection = styled.div`
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;

	background: rgb(131, 58, 180);
	background: linear-gradient(
		90deg,
		rgba(131, 58, 180, 1) 0%,
		rgba(29, 253, 235, 1) 50%,
		rgba(252, 176, 69, 1) 100%
	);
	display: flex;
	flex: 1;
	padding: 45px;
	width: 100%;
	transition: 0.4s ease all;
	justify-content: center;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

const mainnet = NETWORK(1);

interface IStats {
	average_price: number;
	count: number;
	floor_price: number;
	market_cap: number;
	num_owners: number;
	num_reports: number;
	one_day_average_price: number;
	one_day_change: number;
	one_day_sales: number;
	one_day_volume: number;
	seven_day_average_price: number;
	seven_day_change: number;
	seven_day_sales: number;
	seven_day_volume: number;
	thirty_day_average_price: number;
	thirty_day_change: number;
	thirty_day_sales: number;
	thirty_day_volume: number;
	total_sales: number;
	total_supply: number;
	total_volume: number;
}

export const Projects: React.FunctionComponent = () => {
	useTitle({ page: 'Atlas Corp. Dashboard' });

	const {
		account,
		mainnetProvider: provider,
		nodeProvider,
		tokenContractNode,
		splitterContractNode,
		sharkTokenContractNode,
		sharkSplitterContractNode,
		dbitzTokenContractNode,
		dbitzSplitterContractNode,
		holyTokenContractNode,
		holySplitterContractNode,
		realfaceTokenContractNode,
		realfaceSplitterContractNode,
		cupidsTokenContractNode,
		cupidsSplitterContractNode,		
		dcaveBulovaTokenContractNode,
		dcaveBulovaSplitterContractNode,			
		seaport,
	} = useContext(EthereumContext);

	const [isPartner, setIsPartner] = useState(false);
	useEffect(() => {
		if (account && sharkSplitterContractNode && dbitzSplitterContractNode && holySplitterContractNode && realfaceSplitterContractNode && cupidsSplitterContractNode && dcaveBulovaSplitterContractNode) {
			(async () => {
				const sharkShares = (
					await sharkSplitterContractNode.shares(account)
				).toNumber();
				const dbitzShares = (
					await dbitzSplitterContractNode.shares(account)
				).toNumber();
				const holyShares = (
					await holySplitterContractNode.shares(account)
				).toNumber();	
				const realfaceShares = (
					await realfaceSplitterContractNode.shares(account)
				).toNumber();
				const cupidsShares = (
					await cupidsSplitterContractNode.shares(account)
				).toNumber();	
				const dcaveBulovaShares = (
					await dcaveBulovaSplitterContractNode.shares(account)
				).toNumber();												
				const isPartnerCheck = sharkShares > 0 || dbitzShares > 0 || holyShares > 0 || realfaceShares > 0 || cupidsShares > 0 || dcaveBulovaShares > 0;
				setIsPartner(isPartnerCheck);
			})();
		}
	}, [account, sharkSplitterContractNode, dbitzSplitterContractNode,holySplitterContractNode,realfaceSplitterContractNode,cupidsSplitterContractNode]);
	return (
		<ConnectPageSection>
			<div className='container'>
				<div className='card'>
					<div className='card-body table-responsive'>
						{isPartner ? (
							<>
								<h2 className='card-title text-center'>
									Atlas Corp. Dashboard
								</h2>
								<table className='table table-striped table-hover'>
									<thead className='thead-light'>
										<tr>
											<th colSpan={7} className='text-center table-secondary'>
												Project Stats
											</th>
											<th colSpan={3} className='text-center'></th>
											<th></th>
										</tr>
										<tr>
											<th colSpan={3}></th>
											<th colSpan={2} className='text-center table-info'>
												Contract Balances
											</th>
											<th colSpan={2} className='text-center table-success'>
												Gross Sales Volume
											</th>
											<th colSpan={4} className='text-center table-warning'>
												Your Share
											</th>
											<th></th>
										</tr>
										<tr>
											<th>Project Name</th>
											<th>Total Supply</th>
											<th>Floor Ξ</th>
											<th>ERC 721</th>
											<th>Pmt Split</th>
											<th>Primary</th>
											<th>Secondary</th>
											<th>Share %</th>
											<th>Primary</th>
											<th>Secondary</th>
											<th>Total Ξ</th>
											<th>Links</th>
										</tr>
									</thead>
									<tbody>
										{account &&
											provider &&
											sharkTokenContractNode &&
											sharkSplitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={provider}
													tokenContract={sharkTokenContractNode}
													splitterContract={sharkSplitterContractNode}
													seaport={seaport}
													freeUnits={30}
													royaltyRate={0.025}
													secondaryShare={(3333 / 10000) * 0.3}
													symbol={'Ξ'}
													isMainnet={true}
												/>
											)}
										{account &&
											provider &&
											dbitzTokenContractNode &&
											dbitzSplitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={provider}
													tokenContract={dbitzTokenContractNode}
													splitterContract={dbitzSplitterContractNode}
													seaport={seaport}
													freeUnits={69}
													royaltyRate={0.025}
													secondaryShare={0.075}
													symbol={'Ξ'}
													isMainnet={true}
												/>
											)}
										{account &&
											provider &&
											holyTokenContractNode &&
											holySplitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={provider}
													tokenContract={holyTokenContractNode}
													splitterContract={holySplitterContractNode}
													seaport={seaport}
													freeUnits={150}
													royaltyRate={0.025}
													secondaryShare={0.075}
													symbol={'Ξ'}
													isMainnet={true}
												/>
											)}
										{account &&
											provider &&
											realfaceTokenContractNode &&
											realfaceSplitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={provider}
													tokenContract={realfaceTokenContractNode}
													splitterContract={realfaceSplitterContractNode}
													seaport={seaport}
													freeUnits={1} //THIS CANT BE SET TO ZERO EVEN THOUGH ITS TRUE FOR REAL FACE
													royaltyRate={0.025}
													secondaryShare={0.07}
													symbol={'Ξ'}
													isMainnet={true}
												/>
											)}
										{account &&
											provider &&
											cupidsTokenContractNode &&
											cupidsSplitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={provider}
													tokenContract={cupidsTokenContractNode}
													splitterContract={cupidsSplitterContractNode}
													seaport={seaport}
													freeUnits={45}
													royaltyRate={0.025}
													secondaryShare={0.05}
													symbol={'Ξ'}
													isMainnet={true}
												/>
											)}	
										{account &&
											provider &&
											dcaveBulovaTokenContractNode &&
											dcaveBulovaSplitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={provider}
													tokenContract={dcaveBulovaTokenContractNode}
													splitterContract={dcaveBulovaSplitterContractNode}
													seaport={seaport}
													freeUnits={30} 
													royaltyRate={0.025}
													secondaryShare={0.075}
													symbol={'Ξ'}
													isMainnet={true}
												/>
											)}																					
										{account &&
											nodeProvider &&
											tokenContractNode &&
											splitterContractNode &&
											seaport && (
												<TableRow
													account={account}
													provider={nodeProvider}
													tokenContract={tokenContractNode}
													splitterContract={splitterContractNode}
													seaport={seaport}
													freeUnits={0}
													royaltyRate={0.05}
													secondaryShare={0}
													symbol={'MATIC'}
													isMainnet={false}
												/>
											)}
									</tbody>
									{/* <tfoot>
									<tr>
										<th colSpan={8}></th>
										<th>
											{(state.sharkz.share + state.dbitz.share).toFixed(4)}Ξ
										</th>
										<th>
											{(state.sharkz.share2 + state.dbitz.share2).toFixed(4)}Ξ
										</th>
										<th>
											{(
												state.sharkz.share +
												state.dbitz.share +
												state.sharkz.share2 +
												state.dbitz.share2
											).toFixed(4)}
											Ξ
										</th>
										<th></th>
									</tr>
								</tfoot> */}
								</table>
							</>
						) : (
							<div className='p-5 text-center'>
								<h1>This page is for Atlas partners only.</h1>
							</div>
						)}
					</div>
				</div>
			</div>
		</ConnectPageSection>
	);
};

const TableRow = ({
	account,
	provider,
	tokenContract,
	splitterContract,
	seaport,
	freeUnits,
	royaltyRate,
	secondaryShare,
	symbol,
	isMainnet,
}: {
	account: string;
	provider: providers.Web3Provider;
	tokenContract: ethers.Contract;
	splitterContract: ethers.Contract;
	seaport: OpenSeaPort;
	freeUnits: number;
	royaltyRate: number;
	secondaryShare: number;
	symbol: string;
	isMainnet: boolean;
}) => {
	const tooltipName = `tooltip-${splitterContract.address}`;
	const [hasPrimaryShare, setHasPrimaryShare] = useState(false);
	const [state, setState] = useState({
		projectName: '',
		tokenAddress: '',
		splitterAddress: '',
		tokenBalance: 0,
		splitterBalance: 0,
		supply: 0,
		shares: 0,
		price: 0,
		free: 0,
		share1: 0,
		share1Released: 0,
		share1Balance: 0,
		share1Locked: 0,
		share1Withdrawable: 0,
		sharePercent: 0,
		share2: 0,
		totalSales: 0,
		royaltyRate: 0,
		secondaryShare: 0,
		stats: null,
	});
	const onWithdraw = () => {
		splitterContract
			.release(account)
			.then((data: any) => {
				console.log({ data });
				toast.dark(
					<ExternalLink
						href={`${mainnet!.blockExplorer}tx/${data.hash}`}
						target='_BLANK'
						rel='noreferrer'
					>
						{`Withdrew funds! 🚀🚀 Click to view transaction on Etherscan..`}
					</ExternalLink>
				);
			})
			.catch((error: any) => processError(error));
	};
	useEffect(() => {
		if (
			seaport &&
			account &&
			provider &&
			tokenContract &&
			splitterContract &&
			freeUnits >= 0 &&
			royaltyRate &&
			secondaryShare >= 0
		) {
			(async () => {
				const tokenAddr = tokenContract.address;
				const splitterAddr = splitterContract.address;

				let asset: any;
				if (isMainnet) {
					asset = await seaport.api.getAsset({
						tokenAddress: tokenAddr,
						tokenId: 1,
					});
				}
				// log(asset);
				const tokenBalance = Number(
					await ethers.utils.formatEther(await provider.getBalance(tokenAddr))
				);
				const splitterBalance = Number(
					await ethers.utils.formatEther(
						await provider.getBalance(splitterAddr)
					)
				);
				const share1Released = Number(
					await ethers.utils.formatEther(
						splitterContract.released
							? await splitterContract.released(account)
							: await splitterContract['released(address)'](account)
					)
				);
				const supply = (await tokenContract.totalSupply()).toNumber();
				const priceFn = tokenContract.getPrice
					? tokenContract.getPrice : tokenContract.price;
				let price : any;
				if(priceFn === undefined){
					const saleData = await tokenContract.saleData(1);
					price = Number(await ethers.utils.formatEther(await saleData['price']));
				}else{
					price = Number(await ethers.utils.formatEther(await priceFn()));
				}
				const shares = (await splitterContract.shares(account)).toNumber();
				const totalShares = (await splitterContract.totalShares()).toNumber();
				const percent = shares / totalShares;
				const totalSales = asset?.collection.stats.total_volume || 0;
				const share2 = totalSales * royaltyRate * secondaryShare;
				const share1 = (supply - freeUnits) * price * percent;
				const share1Locked = tokenBalance * percent;
				const share1Withdrawable = parseFloat(
					(share1 - share1Locked - share1Released).toFixed(8)
				);
				setHasPrimaryShare(shares > 0);
				setState({
					projectName: asset?.collection.name || 'unknown',
					tokenAddress: tokenAddr,
					splitterAddress: splitterAddr,
					tokenBalance: tokenBalance,
					splitterBalance: splitterBalance,
					supply: supply,
					shares,
					price: price,
					free: freeUnits,
					totalSales: totalSales,
					share1,
					share1Released,
					share1Balance: share1 - share1Released,
					share1Withdrawable,
					share1Locked,
					sharePercent: percent,
					share2,
					stats: asset?.collection.stats || 'unknown',
					royaltyRate,
					secondaryShare,
				});
			})();
		}
	}, [seaport, account, provider, tokenContract, splitterContract]);
	return seaport &&
		account &&
		provider &&
		tokenContract &&
		splitterContract &&
		freeUnits &&
		royaltyRate &&
		secondaryShare &&
		hasPrimaryShare ? (
		<tr className='text-nowrap'>
			<td>{state.projectName}</td>
			<td>
				<TotalSupply supply={state.supply} stats={state.stats} />
			</td>
			<td>
				<Floor stats={state.stats} />
			</td>
			<td>
				{state.tokenBalance.toFixed(4)}
				{symbol}
			</td>
			<td>
				{state.splitterBalance.toFixed(4)}
				{symbol}
			</td>
			<td>
				{((state.supply - state.free) * state.price).toFixed(4)}
				{symbol}
			</td>
			<td>
				<SecondarySales stats={state.stats} />
			</td>
			<td>{state.sharePercent * 100}%</td>
			<td data-tip data-for={tooltipName}>
				<span className={'d-flex'}>
					{state.share1.toFixed(4)}
					{symbol}
					{state.share1Withdrawable > 0 ? (
						<div onClick={onWithdraw}>
							&nbsp;&nbsp;
							<ImBoxRemove />
						</div>
					) : (
						<></>
					)}
				</span>
				<ReactTooltip id={tooltipName} type='dark' effect='solid'>
					<table>
						<tbody>
							<tr>
								<td>
									<b>Gross Total:</b>
								</td>
								<td>
									{state.share1.toFixed(8)}
									{symbol}
								</td>
							</tr>
							<tr>
								<td>
									<b>Less Released:</b>
								</td>
								<td>
									{state.share1Released.toFixed(8)}
									{symbol}
								</td>
							</tr>
							<tr>
								<td>
									<b>Less Frozen in Token Contract:</b>
								</td>
								<td>
									{state.share1Locked.toFixed(8)}
									{symbol}
								</td>
							</tr>
							<tr>
								<td>
									<b>Amount Withdrawable:</b>
								</td>
								<td>
									{state.share1Withdrawable.toFixed(8)}
									{symbol}
								</td>
							</tr>
						</tbody>
					</table>
				</ReactTooltip>
			</td>
			<td>
				{state.share2.toFixed(4)}
				{symbol}
			</td>
			<td>
				<b>
					{(state.share1 + state.share2).toFixed(4)}
					{symbol}
				</b>
			</td>
			<td>
				<Options
					tokenAddress={state.tokenAddress}
					splitterAddress={state.splitterAddress}
				/>
			</td>
		</tr>
	) : (
		<></>
	);
};

const Floor = ({ stats }: { stats: IStats | null }) => {
	return stats ? <>{stats.floor_price}Ξ</> : <></>;
};
const TotalSupply = ({
	supply,
	stats,
}: {
	supply: number;
	stats: IStats | null;
}) => {
	return stats ? (
		<>
			{supply} ({stats.num_owners} owners)
		</>
	) : (
		<></>
	);
};
const SecondarySales = ({ stats }: { stats: IStats | null }) => {
	return stats ? (
		<>
			{stats.total_volume.toFixed(4)}Ξ ({stats.total_sales})
		</>
	) : (
		<></>
	);
};

const Options = ({
	tokenAddress,
	splitterAddress,
}: {
	tokenAddress: string;
	splitterAddress: string;
}) => {
	return (
		<span>
			<a href={`https://etherscan.io/address/${tokenAddress}`} target='_BLANK'>
				<AiFillPicture />
			</a>
			<a
				href={`https://etherscan.io/address/${splitterAddress}`}
				target='_BLANK'
			>
				<MdMoneyOff />
			</a>
		</span>
	);
};
