import styled from 'styled-components';
import { useContext } from 'react';
import { Button, colors, EthereumContext, useTitle, constants } from '../index';

const ConnectPageSection = styled.div`
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background: rgb(131, 58, 180);
	background: linear-gradient(
		90deg,
		rgba(131, 58, 180, 1) 0%,
		rgba(29, 253, 235, 1) 50%,
		rgba(252, 176, 69, 1) 100%
	);
	display: flex;
	flex: 1;
	padding: 45px;
	width: 100%;
	transition: 0.4s ease all;
	justify-content: center;
	color: ${({ theme }: { theme: string }) =>
		theme === 'dark' ? colors.text.section.dark : colors.text.section.light};
`;

const ConnectFrame = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 400px;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
`;

const ConnectTitleFrame = styled.div``;
const ConnectTitle = styled.h1`
	font-family: 'Archivo Black', sans-serif;
	text-shadow: 2px 2px 10px #000000, 2px 2px 10px #000000, 2px 2px 10px #000000;
	letter-spacing: 2px;
	text-align: center;
	color: white;
`;
const RegText = styled.span`
	font-family: 'Rubik', sans-serif;
`;

const ConnectButtonFrame = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

export const Connect: React.FunctionComponent = () => {
	useTitle({ page: 'Connect Wallet' });
	const { connect } = useContext(EthereumContext);
	return (
		<ConnectPageSection className='noselect'>
			<ConnectFrame>
				<ConnectTitleFrame>
					<ConnectTitle>
						<RegText>Login to access</RegText> <b>{constants.projectName}</b>
					</ConnectTitle>
				</ConnectTitleFrame>

				<ConnectButtonFrame>
					<Button
						whileTap={{ scale: 0.9 }}
						whileHover={{ scale: 1.1 }}
						onClick={connect}
					>
						Connect Wallet
					</Button>
				</ConnectButtonFrame>
			</ConnectFrame>
		</ConnectPageSection>
	);
};
