import React from 'react';
import styled from 'styled-components';

const StatusContainer = styled.div`
	font-size: 0.75rem;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const TextWrap = styled.span`
	transition: 0.2s ease-in-out all;
	background-color: white;
	padding: 7px 10px;
	border-radius: 8px;
	box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
		2px 1px 3px rgba(0, 0, 0, 0);
`;

export const Status: React.FC = props => {
	return (
		<StatusContainer>
			<TextWrap>{props.children}</TextWrap>
		</StatusContainer>
	);
};
