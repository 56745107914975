import abi_splitter from './abis/abi_splitter.json';
import abi_storage from './abis/abi_storage.json';
import abi_tokens from './abis/abi_tokens.json';
import abi_sharkz_token from './abis/abi_sharkz_token.json';
import abi_dbitz_token from './abis/abi_dbitz_token.json';
import abi_sharkz_splitter from './abis/abi_sharkz_splitter.json';
import abi_dbitz_splitter from './abis/abi_dbitz_splitter.json';
import abi_holy_splitter from './abis/abi_holy_splitter.json';
import abi_holy_token from './abis/abi_holy_token.json';
import abi_realface_splitter from './abis/abi_realface_splitter.json';
import abi_realface_token from './abis/abi_realface_token.json';
import abi_cupids_splitter from './abis/abi_cupids_splitter.json';
import abi_cupids_token from './abis/abi_cupids_token.json';
import abi_dcaveBulova_splitter from './abis/abi_dcaveBulova_splitter.json';
import abi_dcaveBulova_token from './abis/abi_dcaveBulova_token.json';

//msgs splitter contract
export const msgsSplitterContractInformation: { address: string; abi: any } = {
	address: '0xb5D2B7F88E929d0bf35e1653daabeEfa0245AB5f', //mainnnet v2
	abi: abi_splitter,
};

//msg storage contract
export const storageContractInformation: { address: string; abi: any } = {
	address: '0x50f400a305E0cCdaAbC3FA8f5B3850282A5e756a', //mainnnet v2
	abi: abi_storage,
};

//Erc 721 Contract
export const tokenContractInformation: { address: string; abi: any } = {
	address: '0xC66aA7AA61cb8ED379681bc20a8b6b6F57947B5F', //mainnet v2
	abi: abi_tokens,
};

//Snarky Sharkz Erc 721 Contract
export const sharkzTokenContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0x67360de9ba51ab59bf0d6a4edad27b9bc0b05755',
	abi: abi_sharkz_token,
};

//Snarky Sharkz Payment Splitter
export const sharkzSplitterContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0xC66aA7AA61cb8ED379681bc20a8b6b6F57947B5F',
	abi: abi_sharkz_splitter,
};

//Degenibitz Erc 721 Contract
export const dbitzTokenContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0x3c02f2ed41be7189e2761144d67d9f32aa90fedf',
	abi: abi_dbitz_token,
};

//Degenibitz Payment Splitter
export const dbitzSplitterContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0x7b4d5CCD0BB7946444C74bB3E239EA1c553d5386',
	abi: abi_dbitz_splitter,
};

//Holyones Erc 721 Contract
export const holyTokenContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0xf8a8db29a9fba05ac4e36b8d7df6d47d120247fd',
	abi: abi_holy_token,
};

//Holyones Payment Splitter
export const holySplitterContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0x2C554b28879d44f254491f32d277dd59aA7379a1',
	abi: abi_holy_splitter,
};

//Realface Erc 721 Contract
export const realfaceTokenContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0xf9Ffb3A4dB1507125f566a5B0e19733aebfb9Ae1',
	abi: abi_realface_token,
};

//Realface Payment Splitter
export const realfaceSplitterContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0xe0c7284ec57c27792f1fd977be5cfbe6c6427656',
	abi: abi_realface_splitter,
};

//Cupids Erc 721 Contract
export const cupidsTokenContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0x1D07749A72e8F66FBe626e3d66B7c6A257a4CF86',
	abi: abi_cupids_token,
};

//Cupids Payment Splitter
export const cupidsSplitterContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0xe9bf0cA8F30518DdE9F295C515364bF0B9a14c30',
	abi: abi_cupids_splitter,
};

//DCave Bulova Erc 721 Contract
export const dcaveBulovaTokenContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0x4c46b0562CB71AF8ADcfb659D410283f632Fe9A3',
	abi: abi_dcaveBulova_token,
};

//DCave Bulova Payment Splitter
export const dcaveBulovaSplitterContractInformation: {
	address: string;
	abi: any;
} = {
	address: '0xE063b148a2C65FE3DD3eaB972873db01382F2922',
	abi: abi_dcaveBulova_splitter,
};


